<template>
  <div class="form-layout">
    <Back title="自由职业者签约"></Back>
    <van-form style="padding-bottom: 30px;" @submit="submit" label-align="left" ref="formData" label-width="90px">
      <van-field
        v-model="formData.name"
        name="姓名"
        label="姓名"
        :required="true"
        placeholder="请填写姓名"
        :rules="[{ required: true }]"
      ></van-field>
      <van-field
        v-model="formData.mobile"
        name="手机号码"
        label="手机号码"
        type="tel"
        :required="true"
        placeholder="请填写手机号码"
        :rules="[{validator:checkPhone, required: true }]"
      ></van-field>
      <van-field
        v-show="!formData.id"
        v-model="formData.vcode"
        center
        clearable
        type="digit"
        maxlength="6"
        label="短信验证码"
        name="短信验证码"
        :required="true"
        placeholder="请填写验证码"
        :rules="[{ required: true }]"
      >
        <template #button>
          <van-button class="btn-vcode" v-if="!vcodeLoading" :loading="vcodeLoading" @click="sendVcode" native-type="button" size="small" type="info">获取验证码</van-button>
          <van-button class="btn-vcode" v-else  native-type="button" size="small" type="info" >{{sendCodeText}}秒后重新获取</van-button>
        </template>
      </van-field>
      <van-field
        v-model="formData.bankCardNo"
        name="银行卡号"
        label="银行卡号"
        :required="true"
        placeholder="请填写银行卡号"
        :rules="[{ required: true }]"
      ></van-field>
      <van-field
        v-model="formData.idCard"
        label="证件号码"
        :disabled="true"
        :required="true"
        placeholder="请填写证件号码"
        :rules="[{ required: true }]"
      ></van-field>

      <template>
        <van-field
          name="uploader"
          label="身份证正面"
          :required="true"
          :rules="[{ required: true, message: '请上传身份证正面图片' }]"
        >
          <template #input>
            <van-uploader accept="image/*" :max-size="10 * 1024 * 1024" v-model="idCardFrontPath" :after-read="(file => uploadFile(file,'idCardFrontPath'))" :max-count="1"></van-uploader>
          </template>
        </van-field>
        <van-field
          name="uploader"
          label="身份证背面"
          :required="true"
          :rules="[{ required: true, message: '请上传身份证背面图片' }]"
        >
          <template #input>
            <van-uploader accept="image/*" :max-size="10 * 1024 * 1024" v-model="idCardBackPath" :after-read="(file => uploadFile(file,'idCardBackPath'))" :max-count="1"></van-uploader>
          </template>
        </van-field>
      </template>
      <template>
        <van-field
          name="用户服务协议"
          label=""
          :border="false"
          class="empty-label"
        >
          <template #input>
            <van-checkbox v-model="server" />
            <span style="margin-left: 10px;font-size:12px;width: 280px">
            签署合同
            《<router-link class="font-style" :to="{path:'/mine/server',query:{name:'薪航向(上海)财税咨询有限公司',address:'上海市奉贤区场中路629号'}}">薪航向(上海)财税咨询有限公司</router-link>》
          </span>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" style="flex: 1;margin-right: 10px;" native-type="submit">提 交</van-button>
        </div>
      </template>
    </van-form>
  </div>
</template>
<script>
import requestHTAPI from '@/axios/HantangTax'
import Back from '@/components/back/index'
import { debounce } from '@/libs/utils'
export default {
  name: 'userInfo',
  components: {
    Back
  },
  data () {
    return {
      submitStatus: 'default', // 提交数据时的状态
      vcodeLoading: false, // 发送验证码loading
      sendCodeText: 59,
      formData: {
        merNo: '',
        id: '',
        name: '',
        isHmt: 0, // 0-身份证， 1-港澳台
        isSgin: 1,
        isSginContract: 0, // 是否做过电子签手写合同
        idCard: '',
        mobile: '',
        vcode: '',
        bankCardNo: '',
        idCardFrontPath: '',
        idCardBackPath: '',
        liveStatus: false // 是否已通过人脸认证
      },
      server: false,
      idCardFrontPath: [],
      idCardBackPath: []
    }
  },

  activated () {
    this.submitStatus = 'default'
    this.server = !!sessionStorage.getItem('server')

    const { merNo, idCard, name, mobile, bankCardNo } = this.$route.query
    this.$set(this.formData, 'merNo', merNo)
    this.$set(this.formData, 'idCard', idCard)
    this.$set(this.formData, 'name', name || '')
    this.$set(this.formData, 'mobile', mobile || '')
    this.$set(this.formData, 'bankCardNo', bankCardNo || '')
  },

  methods: {
    checkPhone (val) {
      return /^1[0-9]\d{9}$/.test(val)
    },
    // 验证码
    sendVcode () {
      if (this.formData.mobile === '') {
        this.$notify({
          message: '请填写手机号码',
          position: 'top'
        })
        return
      }
      this.vcodeLoading = true
      requestHTAPI.addMessageVO({
        mobiles: this.formData.mobile,
        type: '0'
      }).then(res => {
        if (res.data.code === '0000') {
          this.$notify({ type: 'success', message: '短信验证码发送成功' })
          const timer = setInterval(() => {
            if (this.sendCodeText > 0) {
              this.sendCodeText--
            } else {
              this.sendCodeText = 59
              clearInterval(timer)
              this.vcodeLoading = false
            }
          }, 1000)
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },

    uploadFile (fileInfo, fileName) {
      console.log(fileInfo, fileName)
      const { content, file } = fileInfo
      this.formData[fileName] = file
      this[fileName] = [{ url: content, isImage: true }]
    },
    submit () {
      debounce(() => { this.debounceSubmit() }, 500, false)
    },

    debounceSubmit () {
      if (!this.server) {
        this.$toast('未勾选签署合同')
        return false
      }
      if (this.submitStatus === 'goGP') {
        // 去认证
        this.$router.push({
          path: '/generatePerson',
          query: {
            name: this.formData.name,
            mobile: this.formData.mobile,
            idCard: this.formData.idCard,
            merId: this.formData.merNo
          }
        })
      } else {
        this.$toast.loading({
          message: '加载中...',
          duration: 0,
          forbidClick: true
        })

        const formData = new FormData()
        const data = this.formData
        for (const key in data) {
          formData.append(key, data[key])
        }
        formData.append('content', this.formData.merNo)

        requestHTAPI.scanAddUser(formData).then(res => {
          this.$toast.clear(true)
          if (res.data.code === '0000') {
            this.$notify({ type: 'success', message: '操作成功' })
            if (res.data.data.hasOwnProperty('signUrl')) {
              window.location.href = res.data.data.signUrl
            }
          } else {
            this.$notify({ type: 'danger', message: res.data.message })
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="less">
.form-layout{
  margin: 0 auto;
}
/deep/ .van-uploader__preview-image{
  width: 300px;
  height: 150px;
}
.btn-vcode{
  width: 210px;
  height: 60px;
}
.font-style{
  color: #2186ea;
}
.empty-label{
  padding-left: 4vw;
  width: 100%;
}
/deep/ .van-form {
  padding: 0 4vw 30px;
}
</style>
